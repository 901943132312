import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './button.css'

const Button = (props) => {
  return (
    <div
      id={props.buttonId}
      className={`button-container ${props.rootClassName} `}
    >
      <button className="thq-button-filled button-button">
        <span className="thq-body-small">
          {props.action1 ?? (
            <Fragment>
              <span className="button-text2">Contact Me</span>
            </Fragment>
          )}
        </span>
      </button>
    </div>
  )
}

Button.defaultProps = {
  rootClassName: '',
  buttonId: '',
  action1: undefined,
}

Button.propTypes = {
  rootClassName: PropTypes.string,
  buttonId: PropTypes.string,
  action1: PropTypes.element,
}

export default Button
