import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Button from '../components/button'
import Hero17 from '../components/hero17'
import Features24 from '../components/features24'
import CTA26 from '../components/cta26'
import CTA9 from '../components/cta9'
import Features25 from '../components/features25'
import Features241 from '../components/features241'
import Steps2 from '../components/steps2'
import Testimonial17 from '../components/testimonial17'
import Features18 from '../components/features18'
import Contact7 from '../components/contact7'
import Gallery141 from '../components/gallery141'
import Footer4 from '../components/footer4'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>
          Leading Personal Trainer in Singapore | Adrian Tan Fitness
        </title>
        <meta
          name="description"
          content="ransform your body and life with expert personalized training from a leading personal trainer in Singapore. Over 20 years of experience in health &amp; fitness help you achieve your ideal physique and improved quality of life."
        />
        <meta
          property="og:title"
          content="Leading Personal Trainer in Singapore | Adrian Tan Fitness"
        />
        <meta
          property="og:description"
          content="ransform your body and life with expert personalized training from a leading personal trainer in Singapore. Over 20 years of experience in health &amp; fitness help you achieve your ideal physique and improved quality of life."
        />
      </Helmet>
      <img alt="image" src="/logo-200h.png" className="home-image" />
      <h1 className="home-text100 thq-heading-1">
        <span>
          Welcome to
          <span
            dangerouslySetInnerHTML={{
              __html: ' ',
            }}
          />
        </span>
        <br></br>
        <span>Adrian Tan Fitness!</span>
      </h1>
      <h2 className="home-text104 thq-body-large">
        <span>Home of 2 time Mr. Singapore</span>
        <br></br>
      </h2>
      <p className="home-text107 thq-body-large">
        <span>Maximum results, minimum time.</span>
        <br></br>
      </p>
      <a href="#Contact7" className="home-link">
        <Button
          action1={
            <Fragment>
              <span className="home-text110">Contact Me</span>
            </Fragment>
          }
          buttonId="Button"
          rootClassName="buttonroot-class-name"
          className="home-component10"
        ></Button>
      </a>
      <Hero17
        hero17Id="Hero17"
        image1Src="/square_001-1500w.jpeg"
        image2Src="/square_003-1500w.jpeg"
        image3Src="/square_002-1500w.jpeg"
        image4Src="/square_017-1500w.jpeg"
        image5Src="/square_007-1500w.jpeg"
        image7Src="/square_005-1500w.jpeg"
        image8Src="/square_016-1500w.jpeg"
        image9Src="/square_014-1500w.jpeg"
        image10Src="/img_8455-1500w.jpeg"
        image11Src="/square_006-1500w.jpeg"
        rootClassName="hero17root-class-name"
      ></Hero17>
      <Features24
        feature1Title={
          <Fragment>
            <span className="home-text111">
              An unrivalled personal training experience
            </span>
          </Fragment>
        }
        feature1ImgSrc="/square_004-1400w.jpeg"
        feature1Description={
          <Fragment>
            <span className="home-text112">
              Start your fitness journey with Adrian Tan Fitness and your whole
              perception of personal training will change.
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text113">
              When you start with Adrian,  you are diving into a results-driven
              philosophy which is deeply invested in helping every single client
              who walks through the door achieve a life changing transformation
              beyond even their wildest expectations. Looking good as well as
              feeling great. Inside and out.
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text114">
              <span>
                Adrian’s over 20 years of experience, accountability systems,
                world-class training and nutrition programs built on science and
                data. That means you simply can’t fail, as long as you are
                willing to put in the work &amp; the consistency in training and
                lifestyle.
              </span>
              <br></br>
            </span>
          </Fragment>
        }
      ></Features24>
      <CTA26
        action1={
          <Fragment>
            <span className="home-text117">Contact Adrian now</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text118">
              Take the first step towards achieving your fitness goals with
              Adrian&apos;s proven training methods.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text119">Ready to transform your body?</span>
          </Fragment>
        }
        rootClassName="cta26root-class-name"
      ></CTA26>
      <CTA9
        heading1={
          <Fragment>
            <span className="home-text120">
              <span>Ready to transform your body?</span>
              <br></br>
            </span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text123">Contact Adrian Now</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text124">
              <span>
                Take the first step towards achieving your fitness goals with
                Adrian&apos;s proven training methods.
              </span>
              <br></br>
            </span>
          </Fragment>
        }
      ></CTA9>
      <Features25
        feature1Title={
          <Fragment>
            <span className="home-text127">Adrian&apos;s Model</span>
          </Fragment>
        }
        feature1ImgSrc="/dsc_9101_2-1400w.jpeg"
        feature1Description={
          <Fragment>
            <span className="home-text128">
              Unlike any gym you have experienced before, I do not offer
              memberships at Adrian Tan Fitness. It’s a private personal
              training-only gym. That means you do not have to queue up to wait for
              equipments, etc. Your body is consistently in the state of motion!
              Burning calories and building muscle is the goal.
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text129">
              <span>
                My singular focus is delivering you ‘maximum results in minimum
                time’. Each session is 60 minutes.
              </span>
              <br></br>
              <br></br>
              <span>
                I will help you achieve the absolute maximum results possible
                and give you a measurable return on the investment that your
                time and money deserve.
              </span>
            </span>
          </Fragment>
        }
      ></Features25>
      <Features241
        feature1Title={
          <Fragment>
            <span className="home-text134">
              <span>
                Training by Adrian
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
            </span>
          </Fragment>
        }
        features241Id="Features241"
        feature1ImgSrc="/img_6649-1400w.jpeg"
        feature1Description={
          <Fragment>
            <span className="home-text137">
              I am here every step of the way, inside and outside the gym,
              guiding, supporting and educating to help you accomplish your
              goals. Always contactable via whatsapp or email 24/7.
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text138">
              My Personal Training sessions are private and by appointment only,
              so you never have to queue for equipment, you are guaranteed
              privacy and you can feel stress free to train hard and achieve
              your goals.
            </span>
          </Fragment>
        }
      ></Features241>
      <Steps2
        step1Description={
          <Fragment>
            <span className="home-text139">
              <span>
                My personal training methods have been refined over the past 2
                decades with hundreds of clients to ensure the maximum
                efficiency and effectiveness of training to achieve the best
                results possible.
              </span>
              <br></br>
            </span>
          </Fragment>
        }
        step2Description={
          <Fragment>
            <span className="home-text142">
              <span>
                I understand that every client is unique. So, every one of our
                personal training programs is unique too, customized to each
                individual client’s goals, needs and abilities to ensure i
                deliver maximum results without disrupting your lifestyle.
              </span>
              <br></br>
            </span>
          </Fragment>
        }
        step3Description={
          <Fragment>
            <span className="home-text145">
              <span>
                My methods and results are not based on the quick-fix mentality
                of the wider fitness industry. I will provide you the tools and
                real-world information you need to maintain optimal and
                sustainable health for life.
              </span>
              <br></br>
            </span>
          </Fragment>
        }
      ></Steps2>
      <Testimonial17
        review1={
          <Fragment>
            <span className="home-text148">
              I’ve been training with Adrian for 6 years and I enjoy his style
              which is tailored for me and I have seen many benefits both in
              fitness and health.
            </span>
          </Fragment>
        }
        review2={
          <Fragment>
            <span className="home-text149">
              Adrian has been a great partner and trainer, keeping me on point
              and makes sure the exercises are age appropriate and keeps me
              injury free.
            </span>
          </Fragment>
        }
        review3={
          <Fragment>
            <span className="home-text150">
              Adrian is an awesome trainer and always provides excellent support
              during sessions.
            </span>
          </Fragment>
        }
        review4={
          <Fragment>
            <span className="home-text151">
              With the exceptional guidance of Adrian, I’ve progressively gotten
              stronger and remain injury-free. He cares about my well being and
              progress. His customized training regimen prepared me for the
              Boston Marathon in 2024!
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text152">
              <span>
                I review and take your results personally. Everything we do,
                inside and outside the gym, is focused on giving you the best
                outcomes possible.
              </span>
              <br></br>
              <br></br>
              <span>
                We leave no stone unturned from helping you with health issues
                and stress management, to proper supplementation and nutrition.
              </span>
              <br></br>
              <br></br>
              <span>
                I understand that ‘results’ are more than just visual. My tried
                and proven approach to body &amp; health transformation means we
                will track and measure every metric possible – from body fat and
                blood pressure to sleep and overall well being. So you’ll see
                improvement and changes by the day!
              </span>
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text160">Results</span>
          </Fragment>
        }
        review31={
          <Fragment>
            <span className="home-text161">
              It has been a pleasure training with Adrian. He is knowledgeable,
              professional and very motivating. I always look forward to the
              next session!
            </span>
          </Fragment>
        }
        review41={
          <Fragment>
            <span className="home-text162">
              Adrian is a fantastic coach who knows what he’s doing and helped
              me reach my fitness goals injury free. He is focused on results
              and yet empathetic to the lifestyle of a busy executive.
            </span>
          </Fragment>
        }
        review413={
          <Fragment>
            <span className="home-text163">
              Have been training with Adrian for more than a year and have lost
              14kg of body fat. He has personalized my workouts based on my
              goals and also taking into account that I have some pre existing
              injuries. He is a positive and inspirational trainer!
            </span>
          </Fragment>
        }
        review414={
          <Fragment>
            <span className="home-text164">
              Been training with Adrian for many years! He’s a patient and
              attentive trainer.
            </span>
          </Fragment>
        }
        review415={
          <Fragment>
            <span className="home-text165">
              Great no nonsense training with Adrian for many years! Results
              speak for themselves.
            </span>
          </Fragment>
        }
        author1Src="/ray%20ferguson-200h.jpeg"
        author2Src="/jason%20moo-200h.jpeg"
        author3Src="/john%20lombard-200h.jpeg"
        author4Src="/ramesh%20selvaraj-200h.jpeg"
        review4131={
          <Fragment>
            <span className="home-text166">
              <span>
                Adrian&apos;s personalized workout plans have been a
                game-changer. I can see and feel the difference in my fitness
                and body fat composition. Adrian is a hands-on, careful trainer
                who calibrates his workout plans such that they sufficiently
                challenge me without pushing me beyond my limits or causing
                injury. I have no hesitation in recommending Adrian as a highly
                skilled and dedicated pe trainer.
              </span>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        review4132={
          <Fragment>
            <span className="home-text170">
              I look forward to training with Adrian every time. He keeps things
              simple, safe yet effective. In my years of training with him, I
              have not had a single injury. Training for longevity with Adrian
              is key to my continued active lifestyle.
            </span>
          </Fragment>
        }
        review4133={
          <Fragment>
            <span className="home-text171">
              Adrian designed a program that gradually escalates / minimise risk
              of injury and perhaps most importantly can become a life long
              habit. Not only do I feel better but Adrian has become a friend -
              cheering me on and pushing me when my motivation flags. Who could
              ask for more!
            </span>
          </Fragment>
        }
        author1Name={
          <Fragment>
            <span className="home-text172">Ray Ferguson</span>
          </Fragment>
        }
        author2Name={
          <Fragment>
            <span className="home-text173">Jason Moo</span>
          </Fragment>
        }
        author3Name={
          <Fragment>
            <span className="home-text174">
              John Lombard
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        author3Src1="/sathiaseelan-200h.jpeg"
        author4Name={
          <Fragment>
            <span className="home-text175">Ramesh Selvaraj</span>
          </Fragment>
        }
        author4Src1="/eugene%20chang-200h.jpeg"
        author3Name1={
          <Fragment>
            <span className="home-text176">Sathiaseelan J.</span>
          </Fragment>
        }
        author4Name1={
          <Fragment>
            <span className="home-text177">Eugene Chang</span>
          </Fragment>
        }
        author4Src12="/joseph%20lum-200h.jpeg"
        author4Src13="/shaila%20tanwani-200h.jpeg"
        author4Src14="/mark%20goh-200h.jpeg"
        author4Src15="/simon%20dale-200h.jpeg"
        author4Name13={
          <Fragment>
            <span className="home-text178">Shaila Tanwani</span>
          </Fragment>
        }
        author4Name14={
          <Fragment>
            <span className="home-text179">Mark Go</span>
          </Fragment>
        }
        author4Name15={
          <Fragment>
            <span className="home-text180">Simon Dale</span>
          </Fragment>
        }
        author4Src131="/samuel-chacko-200h.jpeg"
        author4Src132="/chua-soo-yong-200h.jpeg"
        author4Src133="/vikram-chakravarty-200h.jpeg"
        author4Name131={
          <Fragment>
            <span className="home-text181">
              Samuel Chacko
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        author4Name132={
          <Fragment>
            <span className="home-text182">
              Chua Soo Yong
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        author4Name133={
          <Fragment>
            <span className="home-text183">Vikram Chakravarty</span>
          </Fragment>
        }
        author1Position={
          <Fragment>
            <span className="home-text184">
              Renowned Financial Services Chief Executive
            </span>
          </Fragment>
        }
        author2Position={
          <Fragment>
            <span className="home-text185">CEO, Bank of Singapore</span>
          </Fragment>
        }
        author3Position={
          <Fragment>
            <span className="home-text186">CEO, APAC @NTT</span>
          </Fragment>
        }
        author4Position={
          <Fragment>
            <span className="home-text187">
              Partner, Allen &amp; Gledhill LLP
            </span>
          </Fragment>
        }
        author3Position1={
          <Fragment>
            <span className="home-text188">
              Partner, Allen &amp; Gledhill LLP
            </span>
          </Fragment>
        }
        author4Position1={
          <Fragment>
            <span className="home-text189">
              CEO, Leadership Choices PTE LTD
            </span>
          </Fragment>
        }
        author4Position13={
          <Fragment>
            <span className="home-text190">The Royal Group</span>
          </Fragment>
        }
        author4Position14={
          <Fragment>
            <span className="home-text191">VP, Group Property, Singtel</span>
          </Fragment>
        }
        author4Position15={
          <Fragment>
            <span className="home-text192">Advisor &amp; Investor, TPG</span>
          </Fragment>
        }
        author4Position131={
          <Fragment>
            <span className="home-text193">Director at Legis Point LLC</span>
          </Fragment>
        }
        author4Position132={
          <Fragment>
            <span className="home-text194">
              Consultant Spine and Orthopaedic Surgeon
            </span>
          </Fragment>
        }
        author4Position133={
          <Fragment>
            <span className="home-text195">
              Partner - ASEAN, Ernst &amp; Young
            </span>
          </Fragment>
        }
      ></Testimonial17>
      <Features18
        feature1Title={
          <Fragment>
            <span className="home-text196">
              <span>Fitness after 40</span>
              <br></br>
            </span>
          </Fragment>
        }
        feature1ImageSrc="/dsc_9057-1400w.jpeg"
        feature1Description={
          <Fragment>
            <span className="home-text199">
              <span>
                Wonder how can you feel fit, healthy and look fabulous, even in
                your 40s?
              </span>
              <br></br>
              <br></br>
              <span>
                As you age, your muscle mass decreases, your flexibility
                plummets, and you will experience aches and pains throughout
                your body. Recovery also takes longer than before.
              </span>
              <br></br>
              <br></br>
              <span>
                Fret not, with my individualized training and nutrition plans,
                you will enjoy your 40s feeling great while looking fit and
                fabulous. With my training and Nutrition protocols, we will
                increase your lean muscle mass while burning off those stubborn
                love handles. Everything is customized and focused for those in
                their 40s. Your hormone levels will be boosted naturally, and
                you will feel like you are in 20s, while looking younger and
                fresher than ever.
              </span>
              <br></br>
              <br></br>
              <span>
                Don’t just take my word for it, because I live and breathe it!
              </span>
            </span>
          </Fragment>
        }
        features18Id="Features18"
      ></Features18>
      <Contact7
        content1={
          <Fragment>
            <span className="home-text210">
              The only and best investment you can make for your health. Adrian
              Tan Fitness is the best place to invest in your lifelong health
              and achieve incredible results!
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text211">
              Enquire now
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        location1ImageSrc="/square_005-1500w.jpeg"
        location11={
          <Fragment>
            <span className="home-text212">Unreal Personal Training</span>
          </Fragment>
        }
        location1Description1={
          <Fragment>
            <span className="home-text213">
              101 Cecil Street #B2-02,Tong Eng Building
            </span>
          </Fragment>
        }
        location22={
          <Fragment>
            <span className="home-text214">What&apos;s App</span>
          </Fragment>
        }
        location2Description2={
          <Fragment>
            <span className="home-text215">
              Feel free to contact me via whatsapp
            </span>
          </Fragment>
        }
        location2Description12={
          <Fragment>
            <span className="home-text216">Chat on What&apos;s App</span>
          </Fragment>
        }
        location1ImageSrc1="/square_005-1500w.jpeg"
        location2ImageSrc1="/square_002-1500w.jpeg"
        contact7Id="Contact7"
      ></Contact7>
      <Gallery141
        heading1={
          <Fragment>
            <span className="home-text217">
              Check out my clients transformations!
            </span>
          </Fragment>
        }
        image1Src="/before-after-01-600w.jpg"
        image2Src="/before-after-02-600w.jpg"
        image3Src="/before-after-03-600w.jpg"
        image1Src1="/before-after-04-600w.jpg"
        image2Src1="/before-after-11-600w.jpg"
        image2Src2="/before-after-08-600w.jpg"
        image2Src3="/before-after-05-600w.jpg"
        image3Src1="/before-after-06-600w.jpg"
        image1Src11="/before-after-10-600w.jpg"
        image1Src12="/before-after-07-600w.jpg"
        image3Src11="/before-after-12-600w.jpg"
        image3Src12="/before-after-09-600w.jpg"
      ></Gallery141>
      <Footer4></Footer4>
    </div>
  )
}

export default Home
