import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './gallery141.css'

const Gallery141 = (props) => {
  return (
    <div className="gallery141-gallery3 thq-section-padding">
      <div className="gallery141-max-width thq-section-max-width">
        <div className="gallery141-section-title">
          <h2 className="gallery141-text1 thq-heading-2">
            {props.heading1 ?? (
              <Fragment>
                <span className="gallery141-text2">
                  Discover the Key Features
                </span>
              </Fragment>
            )}
          </h2>
        </div>
        <div className="gallery141-content">
          <div className="gallery141-container1">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="gallery141-image11 thq-img-ratio-4-3"
            />
            <img
              alt={props.image1Alt1}
              src={props.image1Src1}
              className="gallery141-image12 thq-img-ratio-4-3"
            />
            <img
              alt={props.image1Alt12}
              src={props.image1Src12}
              className="gallery141-image13 thq-img-ratio-4-3"
            />
            <img
              alt={props.image1Alt11}
              src={props.image1Src11}
              className="gallery141-image14 thq-img-ratio-4-3"
            />
          </div>
          <div className="gallery141-container2">
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="gallery141-image21 thq-img-ratio-4-3"
            />
            <img
              alt={props.image2Alt3}
              src={props.image2Src3}
              className="gallery141-image22 thq-img-ratio-4-3"
            />
            <img
              alt={props.image2Alt2}
              src={props.image2Src2}
              className="gallery141-image23 thq-img-ratio-4-3"
            />
            <img
              alt={props.image2Alt1}
              src={props.image2Src1}
              className="gallery141-image24 thq-img-ratio-4-3"
            />
          </div>
          <div className="gallery141-container3">
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="gallery141-image31 thq-img-ratio-4-3"
            />
            <img
              alt={props.image3Alt1}
              src={props.image3Src1}
              className="gallery141-image32 thq-img-ratio-4-3"
            />
            <img
              alt={props.image3Alt12}
              src={props.image3Src12}
              className="gallery141-image33 thq-img-ratio-4-3"
            />
            <img
              alt={props.image3Alt11}
              src={props.image3Src11}
              className="gallery141-image34 thq-img-ratio-4-3"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Gallery141.defaultProps = {
  image3Src11:
    'https://images.unsplash.com/photo-1552083974-186346191183?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE1fHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&ixlib=rb-4.0.3&w=600',
  image3Alt: 'PlaceholderImage1302',
  image1Alt11: 'PlaceholderImage1302',
  image1Alt12: 'PlaceholderImage1302',
  image3Src:
    'https://images.unsplash.com/photo-1552083974-186346191183?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE1fHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&ixlib=rb-4.0.3&w=600',
  image1Alt1: 'PlaceholderImage1302',
  image3Alt11: 'PlaceholderImage1302',
  image2Src1:
    'https://images.unsplash.com/photo-1574169208507-84376144848b?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDN8fGFic3RyYWN0fGVufDB8fHx8MTcxMDg3MDkzMHww&ixlib=rb-4.0.3&w=600',
  image1Src12:
    'https://images.unsplash.com/photo-1567095761054-7a02e69e5c43?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fGFic3RyYWN0fGVufDB8fHx8MTcxMDg3MDkzMHww&ixlib=rb-4.0.3&w=600',
  image2Src3:
    'https://images.unsplash.com/photo-1574169208507-84376144848b?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDN8fGFic3RyYWN0fGVufDB8fHx8MTcxMDg3MDkzMHww&ixlib=rb-4.0.3&w=600',
  image2Alt: 'PlaceholderImage1302',
  heading1: undefined,
  image1Src1:
    'https://images.unsplash.com/photo-1567095761054-7a02e69e5c43?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fGFic3RyYWN0fGVufDB8fHx8MTcxMDg3MDkzMHww&ixlib=rb-4.0.3&w=600',
  image2Alt2: 'PlaceholderImage1302',
  image2Alt1: 'PlaceholderImage1302',
  image2Src:
    'https://images.unsplash.com/photo-1574169208507-84376144848b?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDN8fGFic3RyYWN0fGVufDB8fHx8MTcxMDg3MDkzMHww&ixlib=rb-4.0.3&w=600',
  image2Src2:
    'https://images.unsplash.com/photo-1574169208507-84376144848b?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDN8fGFic3RyYWN0fGVufDB8fHx8MTcxMDg3MDkzMHww&ixlib=rb-4.0.3&w=600',
  image3Src12:
    'https://images.unsplash.com/photo-1552083974-186346191183?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE1fHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&ixlib=rb-4.0.3&w=600',
  image1Src11:
    'https://images.unsplash.com/photo-1567095761054-7a02e69e5c43?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fGFic3RyYWN0fGVufDB8fHx8MTcxMDg3MDkzMHww&ixlib=rb-4.0.3&w=600',
  image2Alt3: 'PlaceholderImage1302',
  image3Alt1: 'PlaceholderImage1302',
  image3Alt12: 'PlaceholderImage1302',
  image1Src:
    'https://images.unsplash.com/photo-1567095761054-7a02e69e5c43?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fGFic3RyYWN0fGVufDB8fHx8MTcxMDg3MDkzMHww&ixlib=rb-4.0.3&w=600',
  image3Src1:
    'https://images.unsplash.com/photo-1552083974-186346191183?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE1fHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&ixlib=rb-4.0.3&w=600',
  image1Alt: 'PlaceholderImage1302',
}

Gallery141.propTypes = {
  image3Src11: PropTypes.string,
  image3Alt: PropTypes.string,
  image1Alt11: PropTypes.string,
  image1Alt12: PropTypes.string,
  image3Src: PropTypes.string,
  image1Alt1: PropTypes.string,
  image3Alt11: PropTypes.string,
  image2Src1: PropTypes.string,
  image1Src12: PropTypes.string,
  image2Src3: PropTypes.string,
  image2Alt: PropTypes.string,
  heading1: PropTypes.element,
  image1Src1: PropTypes.string,
  image2Alt2: PropTypes.string,
  image2Alt1: PropTypes.string,
  image2Src: PropTypes.string,
  image2Src2: PropTypes.string,
  image3Src12: PropTypes.string,
  image1Src11: PropTypes.string,
  image2Alt3: PropTypes.string,
  image3Alt1: PropTypes.string,
  image3Alt12: PropTypes.string,
  image1Src: PropTypes.string,
  image3Src1: PropTypes.string,
  image1Alt: PropTypes.string,
}

export default Gallery141
