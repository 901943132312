import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './steps2.css'

const Steps2 = (props) => {
  return (
    <div className="steps2-container1 thq-section-padding">
      <div className="steps2-max-width thq-section-max-width">
        <div className="steps2-container2 thq-grid-2">
          <div className="steps2-section-header">
            <h2 className="steps2-text10 thq-heading-2">
              <span>
                My methodology
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </h2>
            <div className="steps2-actions"></div>
          </div>
          <div className="steps2-container3">
            <div className="steps2-container4 thq-card">
              <span className="steps2-text16 thq-body-small">
                {props.step1Description ?? (
                  <Fragment>
                    <span className="steps2-text22">
                      <span>
                        My personal training methods have been refined over the
                        past 2 decades with hundreds of clients to ensure the
                        maximum efficiency and effectiveness of training to
                        achieve the best results possible.
                      </span>
                      <br></br>
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text17 thq-heading-3">01</label>
            </div>
            <div className="steps2-container5 thq-card">
              <span className="steps2-text18 thq-body-small">
                {props.step2Description ?? (
                  <Fragment>
                    <span className="steps2-text25">
                      <span>
                        I understand that every client is unique. So, every one
                        of our personal training programs is unique too,
                        customized to each individual client’s goals, needs and
                        abilities to ensure i deliver maximum results without
                        disrupting your lifestyle.
                      </span>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text19 thq-heading-3">02</label>
            </div>
            <div className="steps2-container6 thq-card">
              <span className="steps2-text20 thq-body-small">
                {props.step3Description ?? (
                  <Fragment>
                    <span className="steps2-text31">
                      <span>
                        My methods and results are not based on the quick-fix
                        mentality of the wider fitness industry. I will provide
                        you the tools and real-world information you need to
                        maintain optimal and sustainable health for life.
                      </span>
                      <br></br>
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text21 thq-heading-3">03</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Steps2.defaultProps = {
  step1Description: undefined,
  step2Description: undefined,
  step3Description: undefined,
}

Steps2.propTypes = {
  step1Description: PropTypes.element,
  step2Description: PropTypes.element,
  step3Description: PropTypes.element,
}

export default Steps2
