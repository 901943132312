import React from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './hero17.css'

const Hero17 = (props) => {
  return (
    <div
      id={props.hero17Id}
      className={`hero17-header78 ${props.rootClassName} `}
    >
      <div className="hero17-content">
        <div className="hero17-row-container1 thq-animated-group-container-horizontal thq-mask-image-horizontal">
          <div className="thq-animated-group-horizontal">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="hero17-placeholder-image10 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="hero17-placeholder-image11 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="hero17-placeholder-image12 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="hero17-placeholder-image13 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image5Alt}
              src={props.image5Src}
              className="hero17-placeholder-image14 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
          <div className="thq-animated-group-horizontal">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="hero17-placeholder-image15 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="hero17-placeholder-image16 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="hero17-placeholder-image17 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="hero17-placeholder-image18 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image5Alt}
              src={props.image5Src}
              className="hero17-placeholder-image19 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt="Hero Image"
              src="https://images.unsplash.com/photo-1534312527009-56c7016453e6?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIxfHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&amp;ixlib=rb-4.0.3&amp;w=1500"
              className="hero17-placeholder-image20 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
        </div>
        <div className="hero17-row-container2 thq-animated-group-container-horizontal thq-mask-image-horizontal">
          <div className="thq-animated-group-horizontal-reverse">
            <img
              alt={props.image7Alt}
              src={props.image7Src}
              className="hero17-placeholder-image21 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image8Alt}
              src={props.image8Src}
              className="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image9Alt}
              src={props.image9Src}
              className="hero17-placeholder-image23 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image10Alt}
              src={props.image10Src}
              className="hero17-placeholder-image24 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image11Alt}
              src={props.image11Src}
              className="hero17-placeholder-image25 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
          <div className="thq-animated-group-horizontal-reverse">
            <img
              alt={props.image7Alt}
              src={props.image7Src}
              className="hero17-placeholder-image26 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image8Alt}
              src={props.image8Src}
              className="hero17-placeholder-image27 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image9Alt}
              src={props.image9Src}
              className="hero17-placeholder-image28 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image10Alt}
              src={props.image10Src}
              className="hero17-placeholder-image29 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image11Alt}
              src={props.image11Src}
              className="hero17-placeholder-image30 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt="Hero Image"
              src="https://images.unsplash.com/photo-1568214379698-8aeb8c6c6ac8?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEyfHxncmFmaWN8ZW58MHx8fHwxNzE1Nzk0OTk5fDA&amp;ixlib=rb-4.0.3&amp;w=1500"
              className="hero17-placeholder-image31 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="hero17-container2">
          <Script
            html={`<style>
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 16px));
    }
  }

  @keyframes scroll-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(-100% - 16px));
    }
  }
</style>
`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

Hero17.defaultProps = {
  image7Alt: 'Nutrition guidance by Adrian',
  image3Alt: 'Adrian coaching a client',
  image7Src: '/img_9203-1500w.jpeg',
  image1Src: '/dsc_9082_2-1500w.jpeg',
  image2Src:
    'https://images.unsplash.com/photo-1583454110551-21f2fa2afe61?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDQ5fHxmaXRuZXNzfGVufDB8fHx8MTcyOTQwNjgwNHww&ixlib=rb-4.0.3&w=1500',
  rootClassName: '',
  hero17Id: '',
  image4Alt: 'Client transformation results',
  image10Alt: "Adrian's Fitness Training Services Logo",
  image10Src:
    'https://images.unsplash.com/photo-1682685797365-6f57bbebffed?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTQwMDQ2MXw&ixlib=rb-4.0.3&q=80&w=1080',
  image9Src:
    'https://images.unsplash.com/photo-1601422407692-ec4eeec1d9b3?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDN8fG11c2NsZXxlbnwwfHx8fDE3Mjk0MDcwMjB8MA&ixlib=rb-4.0.3&w=1500',
  image8Alt: "Adrian's Fitness Training Studio",
  image9Alt: 'Contact Adrian for a consultation',
  image5Src: 'https://play.teleporthq.io/static/svg/default-img.svg',
  image2Alt: 'Adrian working out',
  image4Src:
    'https://images.unsplash.com/photo-1683709342680-23a2a59246f0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTQwMDQ2MHw&ixlib=rb-4.0.3&q=80&w=1080',
  image3Src:
    'https://images.unsplash.com/photo-1434754205268-ad3b5f549b11?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEyfHxtdXNjbGV8ZW58MHx8fHwxNzI5NDA3MDIwfDA&ixlib=rb-4.0.3&w=1500',
  image5Alt: 'Group training session with Adrian',
  image1Alt: "Adrian's Fitness Training Services",
  image8Src:
    'https://images.unsplash.com/photo-1605490855119-94921710a47f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTQwMDQ2MHw&ixlib=rb-4.0.3&q=80&w=1080',
  image11Src: 'https://play.teleporthq.io/static/svg/default-img.svg',
  image11Alt: 'Client testimonials',
}

Hero17.propTypes = {
  image7Alt: PropTypes.string,
  image3Alt: PropTypes.string,
  image7Src: PropTypes.string,
  image1Src: PropTypes.string,
  image2Src: PropTypes.string,
  rootClassName: PropTypes.string,
  hero17Id: PropTypes.string,
  image4Alt: PropTypes.string,
  image10Alt: PropTypes.string,
  image10Src: PropTypes.string,
  image9Src: PropTypes.string,
  image8Alt: PropTypes.string,
  image9Alt: PropTypes.string,
  image5Src: PropTypes.string,
  image2Alt: PropTypes.string,
  image4Src: PropTypes.string,
  image3Src: PropTypes.string,
  image5Alt: PropTypes.string,
  image1Alt: PropTypes.string,
  image8Src: PropTypes.string,
  image11Src: PropTypes.string,
  image11Alt: PropTypes.string,
}

export default Hero17
