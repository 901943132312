import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './cta9.css'

const CTA9 = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="thq-section-max-width">
        <div className="cta9-container2">
          <div className="cta9-content">
            <span className="thq-heading-2">
              {props.heading1 ?? (
                <Fragment>
                  <span className="cta9-text4">Default value</span>
                </Fragment>
              )}
            </span>
            <p className="thq-body-large">
              {props.content1 ?? (
                <Fragment>
                  <span className="cta9-text6">Default value</span>
                </Fragment>
              )}
            </p>
          </div>
          <div className="cta9-container3">
            <div className="cta9-row">
              <div className="cta9-container4">
                <a
                  href="#Contact7"
                  rel="noreferrer noopener"
                  className="cta9-link thq-button-filled"
                >
                  <span>
                    {props.action1 ?? (
                      <Fragment>
                        <span className="cta9-text5">Default value</span>
                      </Fragment>
                    )}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CTA9.defaultProps = {
  heading1: undefined,
  action1: undefined,
  content1: undefined,
}

CTA9.propTypes = {
  heading1: PropTypes.element,
  action1: PropTypes.element,
  content1: PropTypes.element,
}

export default CTA9
