import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './contact7.css'

const Contact7 = (props) => {
  return (
    <div
      id={props.contact7Id}
      className="contact7-container1 thq-section-padding"
    >
      <div className="contact7-max-width thq-section-max-width">
        <div className="contact7-content1 thq-flex-row">
          <div className="contact7-content2">
            <h2 className="thq-heading-2">
              {props.heading1 ?? (
                <Fragment>
                  <span className="contact7-text17">Locations</span>
                </Fragment>
              )}
            </h2>
            <p className="thq-body-large">
              {props.content1 ?? (
                <Fragment>
                  <span className="contact7-text18">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in ero.
                  </span>
                </Fragment>
              )}
            </p>
          </div>
        </div>
        <div className="contact7-content3 thq-flex-row">
          <div className="contact7-container2">
            <img
              alt={props.location1ImageAlt1}
              src={props.location1ImageSrc1}
              className="special-img-2 thq-img-ratio-16-9"
            />
            <h3 className="contact7-text12 thq-heading-3">
              {props.location11 ?? (
                <Fragment>
                  <span className="contact7-text19">
                  </span>
                </Fragment>
              )}
            </h3>
            <p className="thq-body-large">
              {props.location1Description1 ?? (
                <Fragment>
                  <span className="contact7-text20">
                  </span>
                </Fragment>
              )}
            </p>
            <div className="contact7-container3">
              <a
                href="https://maps.app.goo.gl/sag1kpKdqgZW1BEs8"
                target="_blank"
                rel="noreferrer noopener"
                className="thq-body-small thq-button-flat"
              >
                Goolge Maps
              </a>
            </div>
          </div>
          <div className="contact7-container4">
            <img
              alt={props.location2ImageAlt1}
              src={props.location2ImageSrc1}
              className="special-img thq-img-ratio-16-9"
            />
            <h3 className="thq-heading-3">
              {props.location22 ?? (
                <Fragment>
                  <span className="contact7-text21">What&apos;s App</span>
                </Fragment>
              )}
            </h3>
            <p className="thq-body-large">
              {props.location2Description2 ?? (
                <Fragment>
                  <span className="contact7-text22">
                    Feel free to contact me via whatsapp
                  </span>
                </Fragment>
              )}
            </p>
            <div className="contact7-container5">
              <a
                href="https://wa.me/6598562644?text=Hi%20Adrian%2C%20"
                target="_blank"
                rel="noreferrer noopener"
                className="contact7-link2"
              >
                <p className="contact7-text16 thq-body-small thq-button-flat">
                  {props.location2Description12 ?? (
                    <Fragment>
                      <span className="contact7-text23">
                        Chat on What&apos;s App
                      </span>
                    </Fragment>
                  )}
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Contact7.defaultProps = {
  heading1: undefined,
  content1: undefined,
  location1ImageAlt1: 'Fitness Studio Image',
  location1ImageSrc1:
    'https://images.unsplash.com/photo-1605490855119-94921710a47f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTQwMDQ1OHw&ixlib=rb-4.0.3&q=80&w=1400',
  location11: undefined,
  location1Description1: undefined,
  location2ImageSrc1:
    'https://images.unsplash.com/photo-1566568531155-07244e00963d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTQwMDQ1N3w&ixlib=rb-4.0.3&q=80&w=1400',
  location2ImageAlt1: 'Online Consultation Image',
  location22: undefined,
  location2Description2: undefined,
  location2Description12: undefined,
  contact7Id: '',
}

Contact7.propTypes = {
  heading1: PropTypes.element,
  content1: PropTypes.element,
  location1ImageAlt1: PropTypes.string,
  location1ImageSrc1: PropTypes.string,
  location11: PropTypes.element,
  location1Description1: PropTypes.element,
  location2ImageSrc1: PropTypes.string,
  location2ImageAlt1: PropTypes.string,
  location22: PropTypes.element,
  location2Description2: PropTypes.element,
  location2Description12: PropTypes.element,
  contact7Id: PropTypes.string,
}

export default Contact7
