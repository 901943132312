import React from 'react'

import './footer4.css'

const Footer4 = (props) => {
  return (
    <footer className="footer4-footer7 thq-section-padding">
      <div className="footer4-max-width thq-section-max-width">
        <div className="footer4-credits">
          <div className="footer4-row">
            <div className="footer4-container">
              <span className="thq-body-small">© Adrian Tan Fitness</span>
            </div>
            <div className="footer4-footer-links"></div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer4
