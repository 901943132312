import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './testimonial17.css'

const Testimonial17 = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="testimonial17-max-width thq-section-max-width">
        <div className="testimonial17-container10">
          <h2 className="thq-heading-2">
            {props.heading1 ?? (
              <Fragment>
                <span className="testimonial17-text54">
                  Client Testimonials
                </span>
              </Fragment>
            )}
          </h2>
          <span className="testimonial17-text11 thq-body-small">
            {props.content1 ?? (
              <Fragment>
                <span className="testimonial17-text85">
                  Training with Adrian has been a life-changing experience for
                  me. His personalized approach and motivation have helped me
                  achieve my fitness goals faster than I ever imagined.
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="thq-grid-2">
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card10"
              >
                <div className="testimonial17-container12">
                  <img
                    alt={props.author1Alt}
                    src={props.author1Src}
                    className="testimonial17-image10"
                  />
                  <div className="testimonial17-container13">
                    <strong className="thq-body-large">
                      {props.author1Name ?? (
                        <Fragment>
                          <span className="testimonial17-text80">
                            Jessica Smith
                          </span>
                        </Fragment>
                      )}
                    </strong>
                    <span className="thq-body-small">
                      {props.author1Position ?? (
                        <Fragment>
                          <span className="testimonial17-text62">
                            Marketing Executive
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text14 thq-body-small">
                  {props.review1 ?? (
                    <Fragment>
                      <span className="testimonial17-text50">
                        Adrian&apos;s training methods are effective and
                        results-driven. I highly recommend him to anyone looking
                        to transform their body and improve their overall
                        health.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card11"
              >
                <div className="testimonial17-container14">
                  <img
                    alt={props.author2Alt}
                    src={props.author2Src}
                    className="testimonial17-image11"
                  />
                  <div className="testimonial17-container15">
                    <strong className="thq-body-large">
                      {props.author2Name ?? (
                        <Fragment>
                          <span className="testimonial17-text79">
                            Michael Johnson
                          </span>
                        </Fragment>
                      )}
                    </strong>
                    <span className="thq-body-small">
                      {props.author2Position ?? (
                        <Fragment>
                          <span className="testimonial17-text82">
                            Entrepreneur
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text17 thq-body-small">
                  {props.review2 ?? (
                    <Fragment>
                      <span className="testimonial17-text69">
                        I have trained with many fitness coaches in the past,
                        but Adrian stands out for his expertise and dedication.
                        He pushes you to your limits while ensuring safety and
                        proper form.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card12"
              >
                <div className="testimonial17-container16">
                  <img
                    alt={props.author3Alt}
                    src={props.author3Src}
                    className="testimonial17-image12"
                  />
                  <div className="testimonial17-container17">
                    <strong className="thq-body-large">
                      {props.author3Name ?? (
                        <Fragment>
                          <span className="testimonial17-text63">
                            Sarah Lee
                          </span>
                        </Fragment>
                      )}
                    </strong>
                    <span className="thq-body-small">
                      {props.author3Position ?? (
                        <Fragment>
                          <span className="testimonial17-text78">Teacher</span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text20 thq-body-small">
                  {props.review3 ?? (
                    <Fragment>
                      <span className="testimonial17-text83">
                        Adrian&apos;s passion for fitness is contagious. He not
                        only helped me achieve my fitness goals but also
                        inspired me to maintain a healthy lifestyle beyond the
                        gym.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card13"
              >
                <div className="testimonial17-container18">
                  <img
                    alt={props.author4Alt}
                    src={props.author4Src}
                    className="testimonial17-image13"
                  />
                  <div className="testimonial17-container19">
                    <strong className="thq-body-large">
                      {props.author4Name ?? (
                        <Fragment>
                          <span className="testimonial17-text59">
                            David Brown
                          </span>
                        </Fragment>
                      )}
                    </strong>
                    <span className="thq-body-small">
                      {props.author4Position ?? (
                        <Fragment>
                          <span className="testimonial17-text55">Athlete</span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text23 thq-body-small">
                  {props.review4 ?? (
                    <Fragment>
                      <span className="testimonial17-text77">
                        Training with Adrian has been a game-changer for my
                        performance. His knowledge of sports science and
                        training techniques has taken my athletic abilities to
                        the next level.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card14"
              >
                <div className="testimonial17-container20">
                  <img
                    alt={props.author3Alt1}
                    src={props.author3Src1}
                    className="testimonial17-image14"
                  />
                  <div className="testimonial17-container21">
                    <strong className="thq-body-large">
                      {props.author3Name1 ?? (
                        <Fragment>
                          <span className="testimonial17-text81">
                            Sarah Lee
                          </span>
                        </Fragment>
                      )}
                    </strong>
                    <span className="thq-body-small">
                      {props.author3Position1 ?? (
                        <Fragment>
                          <span className="testimonial17-text49">Teacher</span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text26 thq-body-small">
                  {props.review31 ?? (
                    <Fragment>
                      <span className="testimonial17-text56">
                        Adrian&apos;s passion for fitness is contagious. He not
                        only helped me achieve my fitness goals but also
                        inspired me to maintain a healthy lifestyle beyond the
                        gym.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card15"
              >
                <div className="testimonial17-container22">
                  <img
                    alt={props.author4Alt1}
                    src={props.author4Src1}
                    className="testimonial17-image15"
                  />
                  <div className="testimonial17-container23">
                    <strong className="thq-body-large">
                      {props.author4Name1 ?? (
                        <Fragment>
                          <span className="testimonial17-text75">
                            David Brown
                          </span>
                        </Fragment>
                      )}
                    </strong>
                    <span className="thq-body-small">
                      {props.author4Position1 ?? (
                        <Fragment>
                          <span className="testimonial17-text66">Athlete</span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text29 thq-body-small">
                  {props.review41 ?? (
                    <Fragment>
                      <span className="testimonial17-text61">
                        Training with Adrian has been a game-changer for my
                        performance. His knowledge of sports science and
                        training techniques has taken my athletic abilities to
                        the next level.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card16"
              >
                <div className="testimonial17-container24">
                  <img
                    alt={props.author4Alt15}
                    src={props.author4Src15}
                    className="testimonial17-image16"
                  />
                  <div className="testimonial17-container25">
                    <strong className="thq-body-large">
                      {props.author4Name15 ?? (
                        <Fragment>
                          <span className="testimonial17-text53">
                            David Brown
                          </span>
                        </Fragment>
                      )}
                    </strong>
                    <span className="thq-body-small">
                      {props.author4Position15 ?? (
                        <Fragment>
                          <span className="testimonial17-text70">Athlete</span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text32 thq-body-small">
                  {props.review415 ?? (
                    <Fragment>
                      <span className="testimonial17-text52">
                        Training with Adrian has been a game-changer for my
                        performance. His knowledge of sports science and
                        training techniques has taken my athletic abilities to
                        the next level.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card17"
              >
                <div className="testimonial17-container26">
                  <img
                    alt={props.author4Alt14}
                    src={props.author4Src14}
                    className="testimonial17-image17"
                  />
                  <div className="testimonial17-container27">
                    <strong className="thq-body-large">
                      {props.author4Name14 ?? (
                        <Fragment>
                          <span className="testimonial17-text73">
                            David Brown
                          </span>
                        </Fragment>
                      )}
                    </strong>
                    <span className="thq-body-small">
                      {props.author4Position14 ?? (
                        <Fragment>
                          <span className="testimonial17-text72">Athlete</span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text35 thq-body-small">
                  {props.review414 ?? (
                    <Fragment>
                      <span className="testimonial17-text48">
                        Training with Adrian has been a game-changer for my
                        performance. His knowledge of sports science and
                        training techniques has taken my athletic abilities to
                        the next level.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card18"
              >
                <div className="testimonial17-container28">
                  <img
                    alt={props.author4Alt13}
                    src={props.author4Src13}
                    className="testimonial17-image18"
                  />
                  <div className="testimonial17-container29">
                    <strong className="thq-body-large">
                      {props.author4Name13 ?? (
                        <Fragment>
                          <span className="testimonial17-text74">
                            David Brown
                          </span>
                        </Fragment>
                      )}
                    </strong>
                    <span className="thq-body-small">
                      {props.author4Position13 ?? (
                        <Fragment>
                          <span className="testimonial17-text58">Athlete</span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text38 thq-body-small">
                  {props.review413 ?? (
                    <Fragment>
                      <span className="testimonial17-text51">
                        Training with Adrian has been a game-changer for my
                        performance. His knowledge of sports science and
                        training techniques has taken my athletic abilities to
                        the next level.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card19"
              >
                <div className="testimonial17-container30">
                  <img
                    alt={props.author4Alt133}
                    src={props.author4Src133}
                    className="testimonial17-image19"
                  />
                  <div className="testimonial17-container31">
                    <strong className="thq-body-large">
                      {props.author4Name133 ?? (
                        <Fragment>
                          <span className="testimonial17-text68">
                            David Brown
                          </span>
                        </Fragment>
                      )}
                    </strong>
                    <span className="thq-body-small">
                      {props.author4Position133 ?? (
                        <Fragment>
                          <span className="testimonial17-text65">Athlete</span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text41 thq-body-small">
                  {props.review4133 ?? (
                    <Fragment>
                      <span className="testimonial17-text84">
                        Training with Adrian has been a game-changer for my
                        performance. His knowledge of sports science and
                        training techniques has taken my athletic abilities to
                        the next level.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card20"
              >
                <div className="testimonial17-container32">
                  <img
                    alt={props.author4Alt132}
                    src={props.author4Src132}
                    className="testimonial17-image20"
                  />
                  <div className="testimonial17-container33">
                    <strong className="thq-body-large">
                      {props.author4Name132 ?? (
                        <Fragment>
                          <span className="testimonial17-text60">
                            David Brown
                          </span>
                        </Fragment>
                      )}
                    </strong>
                    <span className="thq-body-small">
                      {props.author4Position132 ?? (
                        <Fragment>
                          <span className="testimonial17-text57">Athlete</span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text44 thq-body-small">
                  {props.review4132 ?? (
                    <Fragment>
                      <span className="testimonial17-text67">
                        Training with Adrian has been a game-changer for my
                        performance. His knowledge of sports science and
                        training techniques has taken my athletic abilities to
                        the next level.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card21"
              >
                <div className="testimonial17-container34">
                  <img
                    alt={props.author4Alt131}
                    src={props.author4Src131}
                    className="testimonial17-image21"
                  />
                  <div className="testimonial17-container35">
                    <strong className="thq-body-large">
                      {props.author4Name131 ?? (
                        <Fragment>
                          <span className="testimonial17-text71">
                            David Brown
                          </span>
                        </Fragment>
                      )}
                    </strong>
                    <span className="thq-body-small">
                      {props.author4Position131 ?? (
                        <Fragment>
                          <span className="testimonial17-text64">Athlete</span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text47 thq-body-small">
                  {props.review4131 ?? (
                    <Fragment>
                      <span className="testimonial17-text76">
                        Training with Adrian has been a game-changer for my
                        performance. His knowledge of sports science and
                        training techniques has taken my athletic abilities to
                        the next level.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Testimonial17.defaultProps = {
  author4Alt14: 'Image of David Brown',
  author2Src:
    'https://images.unsplash.com/photo-1649123245135-4db6ead931b5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTQwMDQ1OHw&ixlib=rb-4.0.3&q=80&w=1080',
  review414: undefined,
  author1Alt: 'Image of Jessica Smith',
  author3Position1: undefined,
  review1: undefined,
  author3Src:
    'https://images.unsplash.com/photo-1579783901467-31b604eac7a8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTQwMDQ1OHw&ixlib=rb-4.0.3&q=80&w=1080',
  author4Src132:
    'https://images.unsplash.com/photo-1634622260152-e6c16fd6ff33?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTQwMDQ1OHw&ixlib=rb-4.0.3&q=80&w=1080',
  author4Alt133: 'Image of David Brown',
  author4Alt132: 'Image of David Brown',
  review413: undefined,
  author4Alt1: 'Image of David Brown',
  review415: undefined,
  author4Name15: undefined,
  heading1: undefined,
  author4Src1:
    'https://images.unsplash.com/photo-1634622260152-e6c16fd6ff33?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTQwMDQ1OHw&ixlib=rb-4.0.3&q=80&w=1080',
  author3Alt: 'Image of Sarah Lee',
  author4Position: undefined,
  author4Alt: 'Image of David Brown',
  review31: undefined,
  author4Position132: undefined,
  author4Position13: undefined,
  author4Name: undefined,
  author4Name132: undefined,
  review41: undefined,
  author1Position: undefined,
  author3Name: undefined,
  author4Position131: undefined,
  author4Position133: undefined,
  author4Src14:
    'https://images.unsplash.com/photo-1634622260152-e6c16fd6ff33?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTQwMDQ1OHw&ixlib=rb-4.0.3&q=80&w=1080',
  author4Src131:
    'https://images.unsplash.com/photo-1634622260152-e6c16fd6ff33?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTQwMDQ1OHw&ixlib=rb-4.0.3&q=80&w=1080',
  author4Position1: undefined,
  review4132: undefined,
  author4Name133: undefined,
  review2: undefined,
  author4Position15: undefined,
  author4Name131: undefined,
  author4Src133:
    'https://images.unsplash.com/photo-1634622260152-e6c16fd6ff33?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTQwMDQ1OHw&ixlib=rb-4.0.3&q=80&w=1080',
  author2Alt: 'Image of Michael Johnson',
  author4Position14: undefined,
  author4Name14: undefined,
  author4Name13: undefined,
  author4Name1: undefined,
  author4Src:
    'https://images.unsplash.com/photo-1634622260152-e6c16fd6ff33?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTQwMDQ1OHw&ixlib=rb-4.0.3&q=80&w=1080',
  review4131: undefined,
  author4Alt15: 'Image of David Brown',
  review4: undefined,
  author3Position: undefined,
  author2Name: undefined,
  author1Name: undefined,
  author3Src1:
    'https://images.unsplash.com/photo-1579783901467-31b604eac7a8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTQwMDQ1OHw&ixlib=rb-4.0.3&q=80&w=1080',
  author1Src:
    'https://images.unsplash.com/photo-1508185140592-283327020902?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTQwMDQ1N3w&ixlib=rb-4.0.3&q=80&w=1080',
  author3Name1: undefined,
  author2Position: undefined,
  author4Src13:
    'https://images.unsplash.com/photo-1634622260152-e6c16fd6ff33?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTQwMDQ1OHw&ixlib=rb-4.0.3&q=80&w=1080',
  review3: undefined,
  author4Alt13: 'Image of David Brown',
  review4133: undefined,
  content1: undefined,
  author3Alt1: 'Image of Sarah Lee',
  author4Alt131: 'Image of David Brown',
  author4Src15:
    'https://images.unsplash.com/photo-1634622260152-e6c16fd6ff33?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTQwMDQ1OHw&ixlib=rb-4.0.3&q=80&w=1080',
}

Testimonial17.propTypes = {
  author4Alt14: PropTypes.string,
  author2Src: PropTypes.string,
  review414: PropTypes.element,
  author1Alt: PropTypes.string,
  author3Position1: PropTypes.element,
  review1: PropTypes.element,
  author3Src: PropTypes.string,
  author4Src132: PropTypes.string,
  author4Alt133: PropTypes.string,
  author4Alt132: PropTypes.string,
  review413: PropTypes.element,
  author4Alt1: PropTypes.string,
  review415: PropTypes.element,
  author4Name15: PropTypes.element,
  heading1: PropTypes.element,
  author4Src1: PropTypes.string,
  author3Alt: PropTypes.string,
  author4Position: PropTypes.element,
  author4Alt: PropTypes.string,
  review31: PropTypes.element,
  author4Position132: PropTypes.element,
  author4Position13: PropTypes.element,
  author4Name: PropTypes.element,
  author4Name132: PropTypes.element,
  review41: PropTypes.element,
  author1Position: PropTypes.element,
  author3Name: PropTypes.element,
  author4Position131: PropTypes.element,
  author4Position133: PropTypes.element,
  author4Src14: PropTypes.string,
  author4Src131: PropTypes.string,
  author4Position1: PropTypes.element,
  review4132: PropTypes.element,
  author4Name133: PropTypes.element,
  review2: PropTypes.element,
  author4Position15: PropTypes.element,
  author4Name131: PropTypes.element,
  author4Src133: PropTypes.string,
  author2Alt: PropTypes.string,
  author4Position14: PropTypes.element,
  author4Name14: PropTypes.element,
  author4Name13: PropTypes.element,
  author4Name1: PropTypes.element,
  author4Src: PropTypes.string,
  review4131: PropTypes.element,
  author4Alt15: PropTypes.string,
  review4: PropTypes.element,
  author3Position: PropTypes.element,
  author2Name: PropTypes.element,
  author1Name: PropTypes.element,
  author3Src1: PropTypes.string,
  author1Src: PropTypes.string,
  author3Name1: PropTypes.element,
  author2Position: PropTypes.element,
  author4Src13: PropTypes.string,
  review3: PropTypes.element,
  author4Alt13: PropTypes.string,
  review4133: PropTypes.element,
  content1: PropTypes.element,
  author3Alt1: PropTypes.string,
  author4Alt131: PropTypes.string,
  author4Src15: PropTypes.string,
}

export default Testimonial17
